<template>
    <div>
        <div>
            <v-container>
                <v-row>
                    <v-col class="d-flex">
                        <h3 class="ml-auto">NTTR</h3>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div style="background: #c49471; height: 8px;">

        </div>
        <div style="background: #d0a98d">
            <v-container>
                <v-row class="mt-2">
                    <v-col class="py-16">
                        <h4>Nevada Test &#38; Training Range</h4>
                        <h1>Intergovernmental Executive Committee (IEC)</h1>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div style="background: #eacbb4">
            <v-container class="py-1">
            </v-container>
        </div>

        <v-container>
            <v-row>
                <v-col cols="12" sm="9">

                    <p>
                        The National Defense Authorization Act for Fiscal Year 2021 (NDAA FY2021) was enacted into law on Jan. 1, 2021, as Public Law 116-283.
                        <a href="../../public/PL_116-283_2021_NDAA.pdf">Section 2844 of the NDAA FY2021</a> directs the Secretary of the Air Force and the Secretary of the Interior to establish an Intergovernmental Executive Committee (IEC)
                        <i>"for the purpose of exchanging views, information, and advice relating to the management of the natural and cultural resources and discussing and making recommendations to the Interagency Committee with respect to public access needs and requirements"</i>
                        on the lands withdrawn by the NDAA FY2021. The Air Force is communicating and coordinating with the Department of the Interior; State of Nevada; three neighboring counties; and 17 Indian Tribes through the IEC.
                    </p>

                    <h4 class="mt-8">Background Documents</h4>
                    <div class="meeting">
                        <div v-for="(doc,i) in documents" :key="i">
                            <img :src="doc.icon" width="16" />
                            <a target='_blank' :href="prefixURL + '/public/' + doc.file">{{ doc.name }}</a> <br />
                        </div>
                    </div>
              

                    <h4 class="mt-8 mb-2">Reports to Congress</h4>
                    <div class="meeting">
                        <div v-for="(doc,i) in reports" :key="i">
                            <img :src="doc.icon" width="16" />
                            <a target='_blank' :href="prefixURL + '/public/' + doc.file">{{ doc.name }}</a> <br />
                        </div>
                    </div>


                    <h4 class="mt-8 mb-2">IEC Meetings</h4>
                    <div v-for="(meet,i) in meetings" :key="i">
                        <div class="meeting">
                            <div style="font-weight: bold">{{ meet.title }}</div>
                            <p v-if="meet.message.length > 0">{{ meet.message }}</p>
                            <div v-for="(file,k) in meet.files" :key="'k'+k">
                                <img :src="file.icon" width="16" />
                                <a target='_blank' :href="prefixURL + '/public/' + file.file">{{ file.name }}</a> <br />
                            </div>
                        </div>
                    </div>


                </v-col>
                <v-col cols="12" sm="3">
                    <img src="@/assets/Desert1.png" width="200"/>
                </v-col>
            </v-row>

        </v-container>

        <div style="background: #162737; color: #e2edf6;">
            <v-container class="pb-0 mt-16">
                <v-row>
                    <v-col class="col-sm-9 mb-0" style="min-height: 200px;">
                        <br />
                        <p>
                            Contact: <br />
                            NTTR.IEC.Members@us.af.mil
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<style scoped>
    .meeting {
        border: 1px solid #e2d9d2;
        border-radius: 3px;
        padding: 4px 4px 4px 10px;
        max-width: 450px;
        margin-bottom: 10px;
    }
</style>

<script>
    import { urlHelp } from '../mixins/helpers.js'
    const iconPDF = require('@/assets/PDF_32.png')
    const iconIMG = require('@/assets/IMG_32.png')

    export default {
        name: "Landing",
        mixins: [urlHelp],
        data() {
            return {
                prefixURL: "",
                documents: [
                    {
                        icon: iconPDF,
                        name: "PL 116-283 2021 NDAA (PDF)",
                        file: "PL_116-283_2021_NDAA.pdf",
                    },
                    {
                        icon: iconPDF,
                        name: "DNWR NTTR IEC MOU (PDF)",
                        file: "DNWR_NTTR_IEC_MOU_signed.pdf",
                    },
                ],
                reports: [
                    {
                        icon: iconPDF,
                        name: "IEC Report to Congress 16 Dec 24 FWS (PDF)",
                        file: "IEC_Report_to_Congress_16_Dec_24_FWS_signed_leby.pdf",
                    },
                    {
                        icon: iconPDF,
                        name: "Dec 2022 - Activities of the Interagency Committee (PDF)",
                        file: "Report_to_Congress-Activities_of_the_Interagency_Committee_signed_Dec2022.pdf",
                    },
                    
                ],
                meetings: [
                    {
                        title: "19 Dec 2024 / 1000 PST",
                        message: "",
                        files: [
                            {
                                icon: iconPDF,
                                name: "Teams Meeting Info Dec 19th (PDF)",
                                file: "Teams_Info_Dec_19th.pdf",
                            },
                            {
                                icon: iconIMG,
                                name: "DNWR-NTTR MOU Overview (Slides)",
                                file: "DNWR-NTTR_MOU_Overview_(IEC)_v2.pptx",
                            }
                        ]
                    },
                    {
                        title: "4 Sep 2024 / 1000 PST",
                        message: "",
                        files: [
                            {
                                icon: iconPDF,
                                name: "IEC Agenda (PDF)",
                                file: "IEC_4_Sep_2024_Agenda.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "IEC Meeting Transcript (PDF)",
                                file: "IEC_Meeting_Transcript_2024-09-04.pdf",
                            }
                        ]
                    },
                    {
                        title: "27 Mar 2024 / 900 PST",
                        message: "",
                        files: [
                            {
                                icon: iconPDF,
                                name: "IEC Agenda (PDF)",
                                file: "IEC_Agenda_27_Mar_24_final.pdf",
                            },

                            {
                                icon: iconPDF,
                                name: "Meeting Transcript (PDF)",
                                file: "Desert_NWR_and_NTTR_IEC_Meeting_Transcript_20240327.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "2023 SRC SheepHunt (PDF)",
                                file: "2023_SRC_SheepHunt_b.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "Initiatives Slide (PDF)",
                                file: "NTTR_IEC_Slide.pdf",
                            },
                            {
                                icon: iconIMG,
                                name: "Developed Roads and Gravel Pits 11x17 (JPG)",
                                file: "Developed_Roads_and_Gravel_Pits_11x17c.jpg",
                            },
                            {
                                icon: iconPDF,
                                name: "PL110 181 Proposed Equipment Sites b (PDF)",
                                file: "PL110_181_Proposed_Equipment_Sites_b.pdf",
                            },
                        ]
                    },
                    {
                        title: "28 Nov 2023 / 1230 PST",
                        message: "",
                        files: [
                            {
                                icon: iconPDF,
                                name: "IEC Agenda (PDF)",
                                file: "IEC_Meeting3_Agenda_20231122.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "IEC Meeting Transcript (PDF)",
                                file: "IEC_Meeting_Transcript_2023-11-28.pdf",
                            },
                        ]
                    },
                    {
                        title: "4 Apr 2023 / 900 PDT",
                        message: "",
                        files: [
                            {
                                icon: iconPDF,
                                name: "Desert NWR & NTTR IEC Agenda (PDF)",
                                file: "IEC_Desert_NWR_NTTR_Agenda_20230404.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "IEC Comments from FNW (PDF)",
                                file: "IEC_Comments_from_FNW.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "IEC Meeting Minutes 2023-04-04 FINAL (PDF)",
                                file: "IEC_Meeting_Minutes_20230404_FINAL.pdf",
                            },
                        ]
                    },
                    {
                        title: "7 Nov 2022 / 1000 PDT",
                        message: "",
                        files: [
                            {
                                icon: iconPDF,
                                name: "IEC Agenda 7 Nov 22 (PDF)",
                                file: "IEC_Agenda_7_Nov_22.pdf",
                            },
                        ]
                    },
                    {
                        title: "19 May 2022 / 1000 PDT",
                        message: "",
                        files: [
                            {
                                icon: iconPDF,
                                name: "IEC Agenda 19 May (PDF)",
                                file: "IEC_Agenda_19_May_22.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "IEC Meeting Minutes 19 May 2022 FINAL (PDF)",
                                file: "IEC_Meeting_Minutes_19_May_2022-FINAL.pdf",
                            },
                        ]
                    },
                    {
                        title: "25 Feb 2022",
                        message: "",
                        files: [
                            {
                                icon: iconPDF,
                                name: "IEC Agenda 25 Feb (PDF)",
                                file: "IEC_Agenda_25_Feb.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "IEC Meeting Media Advisory (PDF)",
                                file: "IEC_Meeting_Media_Advisory.pdf",
                            },
                            {
                                icon: iconPDF,
                                name: "IEC Meeting Minutes Final (PDF)",
                                file: "IEC_Meeting_Minutes_25_Feb_2022_FINAL.pdf",
                            },


                        ],
                    },
                ],
            }
        },
        //https://frtcmodernization.com/portals/FRTCModernization/files/documents/FY2021_NDAA_Section_2844_-_Intergovernmental_Executive_Committee.pdf
        created() {
            this.prefixURL = this.snipFinalSlash(this.$http.defaults.baseURL).toUpperCase();
        },
    }
</script>
